@keyframes leftToRight {
    0% {
        transform: translateX(-30%);
    }
    100% {
        transform: translateX(0);
    }
}

.leftToRightAnimation {
    animation: leftToRight 0.8s ease-in-out;
}