.pre-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}