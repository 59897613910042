.row_costume
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imagepower.png");
    background-size: cover;
    color: white;
}
.row_costume_blockEV
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imageblockchain.png");
    background-size: cover;
    color: white;
}
.row_costume_demandResponse
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imagepower3.png");
    background-size: cover;
    color: white;
}
.row_costume_digitalTwin
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imagetwin.png");
    background-size: cover;
    color: white;
}
.row_costume_evCharging
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imageevcharge.png");
    background-size: cover;
    color: white;
}
.row_costume_loadflow
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imagepower2.png");
    background-size: cover;
    color: white;
}
.row_costume_stability
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imagestability.png");
    background-size: cover;
    color: white;
}
.row_costume_reselience
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imagereselience.png");
    background-size: cover;
    color: white;
}
.row_costume_gmd
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/Aurora.png");
    background-size: cover;
    color: white;
}
.row_costume_hardware
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imagehardware.png");
    background-size: cover;
    color: white;
}
.row_costume_IOT
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imageIOT.png");
    background-size: cover;
    color: white;
}
.row_costume_QuantumComputing
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imageQuantum.png");
    background-size: cover;
    color: white;
}
.row_costume_AI
{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5)) , url("/src/images/imagenetwork.png");
    background-size: cover;
    color: white;
}