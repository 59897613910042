.quick-links {
    padding: 2rem;
    background-color: #f5f5f5;
    text-align: center;
    font-size: 0.9rem;
    overflow-y: auto;
    border-top: 1px solid #e0e0e0;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1);
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.separator {
    margin: 0 0.5rem;
}

.info {
    font-weight: bold;
}

.copyright {
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .quick-links {
        font-size: 0.8rem;
        max-height: 200px;
    }
}

@media (max-width: 480px) {
    .quick-links {
        font-size: 0.7rem;
        max-height: 150px;
    }
}
