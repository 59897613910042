.toggle-container {
    width: 100px;
    height: 40px;
    background-color: #ccc;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
}

.toggle-handle {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.toggle-container.on {
    background-color: lightgreen;
}

.toggle-container.on .toggle-handle {
    transform: translateX(60px);
}